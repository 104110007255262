.breakdown-container {
  margin-top: 24px;
  padding: 28px;
  background-color: #fafafa;
}
.breakdown-container .breakdown-header {
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.breakdown-container .breakdown-item {
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.breakdown-container .breakdown-item:last-of-type {
  padding-bottom: 0;
  border-bottom: none;
}
.breakdown-container .breakdown-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.breakdown-container .breakdown-info .breakdown-info-key {
  font-size: 14px;
  line-height: 16px;
}
.breakdown-container .sub-breakdown-info {
  margin-top: 20px;
  padding-left: 10px;
}
.breakdown-container .sub-breakdown-info li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.breakdown-container .sub-breakdown-info li span {
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
