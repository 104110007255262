.modal-wrapper {
  width: 580px !important;
}
.modal-wrapper .shopee-food-modal-header {
  padding: 28px;
  border-bottom: none;
}
.modal-wrapper .shopee-food-modal-body {
  padding: 28px;
}
.modal-wrapper .shopee-food-modal-close-x {
  font-size: 22px;
}
.dailog-title {
  height: 40px;
  padding-left: 52px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  line-height: 40px;
  background-image: url('~@/assets/img/ShopeeFoodLogo.png');
  background-repeat: no-repeat;
  background-size: auto 40px;
}
.popover-content {
  max-width: 300px;
}
.tips-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 8px;
  background-image: url('~@/assets/img/msg-info.png');
  background-repeat: no-repeat;
  background-size: 16px;
}
.tips-icon-popover {
  position: relative;
  top: 2px;
  margin-left: 0;
}
.content-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding: 12px 0;
}
