@keyframes modalSlideIn {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes modalSlideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.ReactModalPortal{
  .user-wallet-restaurant-modal{
    background-color: #ffffff;
    .container-fluid{
      padding: 0px !important;
    }
  }
}

.ReactModal__Content {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  animation-name: modalSlideIn;
  animation-duration: 0.5s;
}

.ReactModal__Content--before-close {
  opacity: 0;
  animation-name: modalSlideOut;
  animation-duration: 0.5s;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.now-modal-header {
  box-sizing: border-box;
  min-height: 60px;
  padding: 18px 20px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #f5f5f5;
}

.now-modal-body {
  padding: 12px 20px;
}

.now-modal-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  padding: 18px 0 18px 20px;

  & .now-modal-button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.now-modal-button {
  display: inline-block;
  padding: 7px 33px;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.4s;

  &-primary {
    color: #fff;
    background-color: var(--red);

    &:hover {
      background-color: rgba(238, 77, 45, 0.75);
    }

    &:active {
      color: #fff;
      background-color: var(--red);
      outline: none;
    }
  }

  &-secondary {
    color: #fff;
    background-color: #3c8dbc;

    &:hover {
      background-color: #2072bc;
    }

    &:active {
      color: #fff;
      background-color: #6296bc;
      outline: none;
    }
  }

  &-cancel {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.26);

    &:hover {
      color: #ffffff;
      background-color: #afafaf;
    }

    &:active {
      color: #fff;
      background-color: #727272;
      outline: none;
    }
  }

  &-disabled {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    box-shadow: none;
    opacity: 0.65;
  }
}
