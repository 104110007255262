@import 'settings/settings';
@import 'settings/common';

// Sprite styles
// @import 'sprites/sprite';
// @import 'sprites/icons';

// Styles for pages
.wrapper {
  // prevent auto zoom on mobile
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 16px;
  }
}

.now-text {
  color: #d52f33;
  font-weight: bold;
}

.is-active {
  display: block !important;
}

#modal-sorry.modal-alert .modal-body {
  background-image: none;
}
.now-loading-restautants {
  background-color: #ffffff;
}

.item-navigation a.active {
  color: #cf2127;
}

.item-navigation a {
  color: #464646;
}

.table-row {
  .order-pagination {
    margin: 0 auto;
  }
}

.input-field input.required-data {
  box-shadow: 0 1px 0 0 #cf2127;
  border-color: #cf2127;
}

.input-field input.required-data + label {
  color: #cf2127;
}

.link-button {
  color: #0f76a9;
  cursor: pointer;
}

.now-loading-restaurant {
  background-color: #ffffff;
}

input.required-data,
select.required-data {
  border-bottom-color: #cf2127 !important;
  outline: none;
}

.form-reg-app-merchant .input-group select {
  background-color: #f2f2f2;
  font-size: 14px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 0 10px;
  margin-bottom: 0;
  height: 45px;
  width: 100%;
}

.pac-container {
  z-index: 10000 !important;
}

.mar-right10 {
  margin-right: 10px;
}

.mar-top30 {
  margin-top: 30px;
}

.mar-bottom30 {
  margin-bottom: 30px;
}

.menu-restaurant-detail .promotions-order {
  display: block !important;
}

.list-service .service-image i {
  display: block;
  margin: auto;
}

#plusone-div {
  margin-left: 10px !important;
  position: absolute;
  top: 4px;
}

.now-detail-restaurant .link-merchant {
  margin-bottom: 8px;
}

.share-social-box {
  display: inline-block;
}

.dropdown-menu .dropdown-item {
  position: relative;
}

.now-bill-restaurant .cart-stats {
  border: 0;
}

.user-profile-update .not-validate,
.user-profile-update .validate {
  right: 10px !important;
  top: 5px !important;
  background: #ffffff;
}

.padding-0 {
  padding: 0 !important;
}

.z-index-4 {
  z-index: 4;
}

.width-fix100 {
  width: 100px;
}

.width-fix200 {
  width: 150px;
}
.w-60 {
  width: 60% !important;
}

.text-sender {
  color: #ce7c46;
}
.text-receiver {
  color: #3e5628;
}

.clickable {
  cursor: pointer;
}

.Toastify__toast--info {
  background: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.Toastify__toast--success {
  background: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.Toastify__toast--warning {
  background: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.Toastify__toast--error {
  background: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.custom-checkbox .custom-control-label:before {
  background: white;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #ee4d2d;
  border-color: #ee4d2d;
}
