.main-content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 134px);
  background-color: #ececec;
  padding: 15px;
  .wrapper-header {
    font-size: 15px;
    background-color: #d8d8d8;
    padding: 10px 5px;
    .form-control {
      height: auto;
    }
  }
}

.group-btn-view {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;

  .btn-view {
    font-size: 20px;
    padding: 2px 7px;
    margin-left: 10px;

    &:hover {
      color: $white;
    }
  }
}

.title-page {
  font-size: 23px;
  color: $gray-900;
  margin-bottom: 15px;
}

.form-required {
  border: 1px solid #dc3545;
}

.toggle-label {
  position: relative;
  display: block;
  width: 80px;
  height: 32px;
  margin-top: 8px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
}
.toggle-label input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.toggle-label input[type='checkbox'] + .back {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
  transition: background 150ms linear;
}
.toggle-label input[type='checkbox']:checked + .back {
  background: #30b566;
}

.toggle-label input[type='checkbox'] + .back .toggle {
  display: block;
  position: absolute;
  content: ' ';
  background: #fff;
  width: 50%;
  height: 100%;
  transition: margin 150ms linear;
}
.toggle-label input[type='checkbox']:checked + .back .toggle {
  margin-left: 40px;
}
.toggle-label .label {
  display: block;
  position: absolute;
  width: 50%;
  color: #ddd;
  line-height: 32px;
  text-align: center;
  font-size: 13px;
}
.toggle-label .label.on {
  left: 0px;
}
.toggle-label .label.off {
  right: 0px;
}

.toggle-label input[type='checkbox']:checked + .back .label.on {
  color: #fff;
}
.toggle-label input[type='checkbox'] + .back .label.off {
  color: #fff;
}
.toggle-label input[type='checkbox']:checked + .back .label.off {
  color: #ddd;
}

.custom-control-input {
  position: relative;
  width: 16px;
  height: 16px;
  font-size: 14px;
}
.custom-control-input::after {
  display: none;
  position: absolute;
  top: 0;
  background-color: $red;
  color: $red;
  width: 16px;
  height: 16px;
  visibility: visible;
  padding-left: 0px;
  text-align: center;
  content: ' ';
  border-radius: 3px;
}

.custom-control-input:checked::after {
  display: inline-block;
  content: '✓';
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
