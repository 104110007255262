.tnx-price {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 20px;
  word-break: break-all;
}
.tnx-price.block {
  display: block;
}
.tnx-price.line-through {
  color: #bdbdbd;
  text-decoration: line-through;
}
.tnx-price .unit {
  font-size: var(--unit-size, 16px);
}
.tnx-price .price {
  font-size: var(--price-size, 16px);
}
