.icon {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.icon-sprite {
  background-image: url('../../images/icon/icon-sprite.png');
  background-repeat: no-repeat;
  background-size: 608px 309px;
}

.icon-airpay {
  @extend .icon-sprite;
  background-position: -146px -74px;
  width: 65px;
  height: 65px;
}

.icon-money {
  position: relative;
  display: inline-block;
  width: 31px;
  height: 16px;
  border-radius: 2px;
  background-color: #fff;
  vertical-align: middle;
  &::before {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    content: 'Cash';
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
  // @extend .icon-sprite;
  // background-position: -146px -10px;
  // width: 65px;
  // height: 60px;
}

.icon-user {
  @extend .icon-sprite;
  background-position: -520px -92px;
  width: 77px;
  height: 77px;
}

.icon-merchant {
  @extend .icon-sprite;
  background-position: -10px -10px;
  width: 128px;
  height: 128px;
}

.icon-arrow-thin {
  border: solid $gray-900;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  border-radius: 1px;

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.icon-dashboard {
  @extend .icon-sprite;
  background-position: -471px -6px;
  width: 40px;
  height: 40px;
}

.icon-order {
  @extend .icon-sprite;
  background-position: -386px -6px;
  width: 40px;
  height: 40px;
}

.icon-wallet {
  @extend .icon-sprite;
  background-position: -299px -10px;
  width: 40px;
  height: 40px;
}

.icon-now-ship {
  // @extend .icon-sprite;
  // background-position: -345px -7px;
  // width: 40px;
  // height: 40px;
  display: inline-block;
  width: 20px;
  height: 17px;
  background:url('../../static/icons/ship.png') no-repeat center / 100%;
}

.icon-menu-manage {
  @extend .icon-sprite;
  background-position: -254px -8px;
  width: 40px;
  height: 40px;
}

.icon-setting {
  @extend .icon-sprite;
  background-position: -428px -7px;
  width: 40px;
  height: 40px;
}

.icon-profile {
  @extend .icon-sprite;
  background-position: -214px -10px;
  width: 40px;
  height: 40px;
}

.icon-now-merchant {
  @extend .icon-sprite;
  background-position: -519px -8px;
  width: 60px;
  height: 60px;
}

.icon-search {
  @extend .icon-sprite;
  background-position: -221px -100px;
  width: 47px;
  height: 47px;
}

.icon-pin-ship-m {
  // @extend .icon-sprite;
  // background-position: -344px -102px;
  // width: 60px;
  // height: 72px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background:url('../../static/icons/book-icon.png') no-repeat center / 100%;
}

.icon-pin-brown {
  // @extend .icon-sprite;
  // background-position: -273px -102px;
  // width: 60px;
  // height: 72px;
  display: inline-block;
  width: 16px;
  height: 20px;
  background:url('../../static/icons/book-location.png') no-repeat center / 100%;
}

.icon-picked {
  @extend .icon-sprite;
  background-position: -414px -98px;
  width: 20px;
  height: 20px;
}

.icon-merchant-wallet {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background: url('../../images/icon/wallet.png') no-repeat center / 14px 13.6px,
    #fff;
  // @extend .icon-sprite;
  // background-position: -445px -104px;
  // width: 62px;
  // height: 52px;
  // border-radius: 11px;
}

.icon-paid {
  // @extend .icon-sprite;
  // background-position: -10px -148px;
  // width: 118px;
  // height: 59px;
  display: inline-flex;
  width: 54px;
  height: 54px;
  background: url('../../static//icons/paid-stamp.png') no-repeat center / 100%;
}

.icon-app-store {
  width: 219px;
  height: 68px;
  @extend .icon-sprite;
  background-position: -365px -184px;
}

.icon-gg-play {
  width: 219px;
  height: 68px;
  @extend .icon-sprite;
  background-position: -136px -184px;
}

.icon-out-of-stock {
  width: 30px;
  height: 30px;
  background-position: -8px -259px;
  @extend .icon-sprite;
}

.icon-restaurant-busy {
  width: 30px;
  height: 30px;
  background-position: -47px -259px;
  @extend .icon-sprite;
}

.icon-restaurant-close {
  width: 30px;
  height: 30px;
  background-position: -85px -259px;
  @extend .icon-sprite;
}

.icon-error-commission {
  width: 30px;
  height: 30px;
  background-position: -125px -259px;
  @extend .icon-sprite;
}

.icon-error-custom {
  width: 30px;
  height: 30px;
  background-position: -202px -259px;
  @extend .icon-sprite;
}

.icon-staff {
  @extend .icon-sprite;
  background-position: -242px -260px;
  width: 40px;
  height: 40px;
}
