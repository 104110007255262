.order-pagination {
  display: flex;
  @include list-unstyled();
  @include border-radius();
  padding: 1rem 0;
  margin-bottom: 0;

  li {
    &:first-child {
      .page-link {
        margin-left: 0;
        @include border-left-radius($border-radius);
      }
    }
    &:last-child {
      .page-link {
        @include border-right-radius($border-radius);
      }
    }

    &.active .page-link {
      z-index: 1;
      color: $white;
      background-color: $blue;
      border-color: $blue;

    }

    &.disabled a {
      color: $pagination-disabled-color;
      pointer-events: none;
      // Opinionated: remove the "hand" cursor set previously for .page-link
      cursor: auto;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
    }
  }

  span {
    font-size: 14px;
    position: relative;
    display: block;
    padding: 6px 11px;
    margin-left: -$pagination-border-width;
    line-height: $pagination-line-height;
    color: #a29c98;
    background-color: #fcfcfc;
    border: 1px solid #e4e4e4;
    height: 32px;

    &:hover {
      z-index: 2;
      text-decoration: none;
      border-color: $red;
    }

    &.active {
      color: #fff;
      background-color: $red;
      border-color: $red;
    }

    &:focus {
      z-index: 2;
      outline: $pagination-focus-outline;
      box-shadow: $pagination-focus-box-shadow;
    }

    // Opinionated: add "hand" cursor to non-disabled .page-link elements
    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }
}

//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}
