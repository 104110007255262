.adaptive-modal {
  padding-bottom: 0;
}
.adaptive-modal-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.adaptive-modal-scroller {
  height: 100%;
  overflow: auto;
}
.adaptive-modal-scroller::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.adaptive-modal-scroller::-webkit-scrollbar-thumb {
  background-color: #00000042;
  border-radius: 4px;
}
.adaptive-modal-scroller-inner {
  padding-bottom: 25px;
}
