.txn-status-tag {
  font-size: 14px;
  line-height: 16px;
}
.txn-status-tag.transaction-completed {
  color: #30b566;
}
.txn-status-tag.transaction-cancelled {
  color: #ee2c4a;
}
.txn-status-tag.transaction-processing {
  color: #f69113;
}
