.main-footer {
  padding: 28px;
  background-color: #fff;
  position: relative;
  transition: width 0.25s;
  transition-timing-function: linear;
  z-index: 2;
  font-size: 12px;
  line-height: 14px;
}
