//
// Basic Bootstrap table
//

.table {
  width: 100%;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.
  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
    border-top: 0;

    .sort-order {
      position: relative;
      display: inline;
    }

    &.asc .sort-order:after,
    &.desc .sort-order:before {
      border: 5px solid transparent;
      content: '';
      height: 0;
      top: 50%;
      left: 5px;
      position: absolute;
      width: 0;
    }

    &.asc {
      .sort-order:after {
        border-bottom-color: green;
        margin-top: -9px;
      }
    }

    &.desc {
      .sort-order:before {
        border-top-color: red;
        margin-top: 0;
      }
    }
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  .table {
    background-color: $body-bg;
  }
}

//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}

// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: (2 * $table-border-width);
    }
  }
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, -9));
}

@include table-row-variant(active, $table-active-bg);

// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
  .thead-dark {
    th {
      color: $table-dark-color;
      background-color: $table-dark-bg;
      border-color: $table-dark-border-color;
    }
  }

  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      border-color: $table-border-color;
    }
  }
}

.table-dark {
  color: $table-dark-color;
  background-color: $table-dark-bg;

  th,
  td,
  thead th {
    border-color: $table-dark-border-color;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: $table-dark-accent-bg;
    }
  }

  &.table-hover {
    tbody tr {
      @include hover {
        background-color: $table-dark-hover-bg;
      }
    }
  }
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}

.page-table-row {
  padding: 12px 10px;
  &:hover {
    background-color: #eee !important;
  }

  .icon-paid {
    position: absolute;
    bottom: 0;
    left: 100%;
  }
}

.page-table-cell {
  padding: 12px 10px;
}

.footer-table {
  padding: 0 20px;
  border-top: 2px solid #dddddd;

  .order-pagination {
    display: inline-flex;
  }

  .page-size-select {
    margin-left: 30px;
    margin-bottom: 2px;
    min-width: 80px;
  }

  .total-row {
    font-size: 15px;
    padding: 15px 0;
  }

  .btn-load {
    font-size: 12px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #dddddd;
    color: #818181;
    width: 32px;
    height: 32px;
    box-shadow: none;
    margin-left: 15px;
  }
}

.order-table {
  background-color: #fff;
  .col-stt {
    width: 1%;
    text-align: center;
  }

  .col-order-code {
    width: 15%;
    text-align: left;
  }

  .col-order-time {
    text-align: center;
    width: 10%;
    vertical-align: middle;
  }

  .col-countdown {
    width: 10%;
    text-align: center;
    vertical-align: middle;
  }

  .col-ship,
  .col-total {
    text-align: right;
    width: 10%;
  }

  .icon-payment {
    position: relative;
    &.flex {
      display: flex;
      align-items: center;
    }
    .icon {
      position: absolute;
      left: 55px;
    }

    // .icon-merchant-wallet {
    //   transform: scale(0.3);
    //   transform-origin: top left;
    // }

    // .icon-money {
    //   transform: scale(0.3);
    //   transform-origin: top left;
    // }
    .more-detail {
      position: absolute;
      top: 0;
      left: 90px;
      border: 0;
      background-color: transparent;
      color: #9a9a9a;
      cursor: pointer;
    }
    .more-detail:focus {
      outline: none;
    }
  }

  .col-status {
    width: 10%;
    text-align: center;
    vertical-align: middle;
    position: relative;

    .btn {
      width: 120px;
      margin: auto;
    }
  }

  .col-detail {
    width: 5%;
    text-align: center;
    vertical-align: middle;
  }

  .col-order {
    width: 80%;
  }

  .col-number {
    width: 15%;
    text-align: center;
  }

  .col-restaurant {
  }

  .col-number-order {
    width: 10%;
    text-align: center;
    position: relative;
  }

  .col-fee {
    width: 20%;
    text-align: right;
  }

  .btn {
    font-size: 12px;
    padding: 6px 4px;
  }

  table {
    td,
    th {
      vertical-align: middle;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: $gray-100;
      }

      &:hover {
        background-color: $gray-200;
      }
    }
  }

  .count-down-time {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 42px;
    text-align: center;
    font-size: 14px;
    color: $white;
    background-color: green;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    &.alert-time {
      background-color: #c00;
    }
  }

  .total-qty {
    font-size: 12px;
    height: 23px;
    margin-top: -3px;
    margin-bottom: -7px;
    color: rgba(0, 0, 0, 0.87);
    span {
      color: #000;
      font-weight: 700;
    }
    div {
      display: inline-block;
    }
  }

  .distance {
    // font-size: 12px;
    font-weight: 700;
    // color: #000ed2;
  }

  .confirm-via {
    width: 100%;
    display: block;
    font-size: 11px;
    margin-top: 1px;
  }
}

.table-report {
  .table-report-header {
    font-weight: 700;

    .table-report-row {
      border-width: 2px;
    }
  }

  .table-report-row {
    border-bottom: 1px solid $gray-300;
    overflow: hidden;
  }

  .table-report-body {
    .table-report-row {
      &:nth-of-type(odd) {
        background-color: $gray-100;
      }

      &:hover {
        background-color: $gray-200;
      }
    }
  }

  .col {
    padding: 12px 10px;
    float: left;
  }

  .col-chart {
    display: none;
  }

  .col-stt {
    width: 5%;
    text-align: center;
  }

  .col-order {
    width: 80%;
  }

  .col-number {
    width: 15%;
    text-align: center;
  }

  &.table-chart {
    .table-report-header {
      .table-report-row {
        border: 0;
      }
    }

    .table-report-body {
      .col-number {
        display: none;
      }
    }

    .table-report-row {
      border: 0;

      &:nth-of-type(odd) {
        background-color: transparent;
      }

      &:hover {
        background-color: transparent;
      }
    }

    .col-stt {
      display: none;
    }

    .col-chart {
      display: block;
      width: 80%;
    }

    .col-order {
      width: 20%;
      text-align: right;
    }

    .row-chart {
      background-color: #4285f4;
      height: 22px;
      text-align: center;
      color: #fff;
      transition: width 0.3s ease;
      width: 20px;
    }
  }
}
