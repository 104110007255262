.order-info-container {
  margin-top: 24px;
  padding: 28px;
  background-color: #fafafa;
}
.order-info-container .order-info-header {
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.order-info-container .order-info-item {
  padding-top: 16px;
}
.order-info-container .order-info-item:last-of-type {
  padding-bottom: 0;
}
.order-info-container .order-info {
  display: flex;
  align-items: flex-start;
}
.order-info-container .order-info .order-info-val {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-width: 164px;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
}
.order-info-container .order-info .item-num {
  min-width: 32px;
  margin-right: 4px;
  line-height: 22px;
}
.order-info-container .order-info .item-name {
  line-height: 22px;
  word-wrap: break-word;
  word-break: break-all;
}
.order-info-container .shopee-food-collapse-icon-position-right > .shopee-food-collapse-item > .shopee-food-collapse-header {
  display: flex;
  align-items: flex-start;
  padding: 0;
}
.order-info-container .shopee-food-collapse-icon-position-right > .shopee-food-collapse-item > .shopee-food-collapse-header .shopee-food-collapse-arrow {
  position: static;
  order: 1;
  margin-top: 10px;
  padding-left: 4px;
}
.order-info-container .shopee-food-collapse-ghost > .shopee-food-collapse-item > .shopee-food-collapse-content > .shopee-food-collapse-content-box {
  padding: 0;
}
.order-info-container .shopee-food-collapse-icon-position-end > .shopee-food-collapse-item > .shopee-food-collapse-header {
  display: flex;
  align-items: flex-start;
  padding: 0;
  padding-right: 32px;
}
.order-info-container .shopee-food-collapse-icon-position-end > .shopee-food-collapse-item > .shopee-food-collapse-header .shopee-food-collapse-arrow {
  position: static;
  order: 1;
  margin-top: 10px;
  padding-left: 4px;
}
.sub-order-info {
  margin-top: 8px;
  margin-left: 36px;
}
.sub-order-info li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.sub-order-info li span {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 16px;
  word-break: break-all;
}
