//body {
//  font-family: "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif;
//
//  background: #f4f7fa;
//  color: $dark-gray;
//
//  letter-spacing: 0;
//  line-height: 1.2;
//}
//html:lang(vi) body {
//  font-family: "Tahoma", "-apple-system", "HelveticaNeue", sans-serif;
//}
//
//#app {
//  margin: 0 auto;
//  display: block;
//}
//
//p {
//  margin: 0;
//}
//a {
//  &, &:hover, &:focus, &:active {
//    text-decoration: none;
//  }
//}
//a:focus, input:focus {
//  outline: 0;
//  border: none;
//}
//img {
//  max-width: 100%;
//}
//
//
//// Scrollbar styles
//::-webkit-scrollbar {
//  width: 4px;
//  height: 4px;
//}
//
//::-webkit-scrollbar-track {
//  background: #ecedee;
//  border-radius: 4px;
//}
//
//::-webkit-scrollbar-thumb {
//  background: #b0b4bc;
//  border-radius: 4px;
//}
//
//
//.no-select {
//  -moz-user-select: none;
//  -webkit-user-select: none;
//  -ms-user-select:none;
//  -o-user-select:none;
//  user-select:none;
//}
//.clickable {
//  cursor: pointer;
//}
//.hoverable {
//  @extend .no-select;
//  &, &:active, &:focus {
//    cursor: pointer;
//    transition: filter .2s;
//
//    -webkit-filter: brightness(100%);
//    filter: brightness(100%);
//  }
//
//  &:not(.active):hover,
//  &.ignore.active:hover {
//    -webkit-filter: brightness(95%);
//    filter: brightness(95%);
//  }
//}
//.disabled {
//  @extend .no-select;
//
//  opacity: .5;
//  cursor: not-allowed !important;
//}
//
//// Disable animation on collapse
//.no-collapsing {
//  -webkit-transition: height 1ms;
//  -moz-transition: height 1ms;
//  -ms-transition: height 1ms;
//  -o-transition: height 1ms;
//  transition: height 1ms;
//}

.fa-rating {
  color: #f4d516;
}