.customNotify .shopee-food-message-notice-content {
  width: 700px;
  padding: 16px 20px;
}
.customNotify.error .shopee-food-message-notice-content {
  background-color: #fff4f4;
}
.customNotify.error .notifyIcon {
  color: #ee2c4a;
}
.customNotify.success .shopee-food-message-notice-content {
  background-color: #f7fffe;
}
.customNotify.success .notifyIcon {
  color: #30b566;
}
.customNotify .anticon {
  margin-right: 0;
}
.customNotify .anticon[aria-label='info-circle'] {
  display: none;
}
.customNotify .notifyBlock {
  display: flex;
  align-items: center;
}
.customNotify .notifyCenter {
  flex: 1;
}
.customNotify .notifyTitle {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
}
.customNotify .notifyMessage {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}
.customNotify .notifyLeft {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.customNotify .notifyIcon {
  font-size: 40px;
}
.customNotify .notifyRight {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 20px;
}
.customNotify .notifyCloseIcon {
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px;
}
