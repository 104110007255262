.font12 {
  font-size: 12px;
}

.font15 {
  font-size: 15px !important;
}

.font18 {
  font-size: 18px !important;
}

.font32 {
  font-size: 32px;
}

.text-black {
  color: $black;
}
