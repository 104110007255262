.transaction-info {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.transaction-info-item {
  width: 50%;
  margin-bottom: 20px;
}
.transaction-info-key {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 16px;
}
.transaction-info-val {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
